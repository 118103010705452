'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitGroupsManager

 # @description

###
angular
.module 'mundoAdmin.units'
.factory 'UnitGroupUsersManager', [
  'MundoDefaultManager'
  'TenantManager'
  'UnitsManager'
  '$mdDialog'
  '$log'
  'UserRoleManager'
  'UserContextManager'
  'RestUtils'
  '_'
  '$filter'
  '$q'
  (
    MundoDefaultManager
    TenantManager
    UnitsManager
    $mdDialog
    $log
    UserRoleManager
    UserContextManager
    RestUtils
    _
    $filter
    $q
  ) ->
    UnitGroupUsersManagerBase = new MundoDefaultManager()
    UnitGroupUsersManagerBase.setParentUrl('units/groups')
    UnitGroupUsersManagerBase.setUrl('users')
    UnitGroupUsersManagerBase.setNewObject(['userContext', 'roles'])
    UnitGroupUsersManagerBase.setUpdateObject(['userContext', 'roles'])

    UnitGroupUsersManagerBase.new = (id, data) ->
      if (data.roles)
        data.roles = @extractIds(data.roles)

      @transport.one(@getParentUrl(), @getParentId()).all(getUrl()).post(_.pick(data, @getNewObject()))

    UnitGroupUsersManagerBase.update = (id, data) ->
      if (data.roles)
        data.roles = @extractIds(data.roles)

      @transport.one(@conf.parentUrl, @conf.parentId).one(@getUrl(), id).customPUT(_.pick(data, @getUpdateObject()))

    UnitGroupUsersManagerBase.getForm = (existingUserContextIds) ->

      [
        key: 'userContext'
        name: 'userContext'
        type: 'autocomplete'
        templateOptions:
          placeholder: 'User context'
          required: true
          displayValue: "label"
          options: do (existingUserContextIds) ->
            userContexts = []
            UserContextManager.getFullList()
            .then (data) ->
              for userContext in data
                if userContext.user? && userContext.user.username?
                  $log.debug "WHAT THE FUCK ?"
                  userContext.label = userContext.user.username
                if userContext.id not in existingUserContextIds then userContexts.push userContext
            userContexts
      ,
        key: 'roles'
        name: 'roles'
        type: 'modelchips'
        templateOptions:
          multiple: true,
          placeholder: 'roles'
          autocompleteLabel : 'add or remove a role'
          required: true
          labelProp: "label"
          valueProp: "id"
          options: UserRoleManager.getFullList({
            'sort' : 'label,ASC'
            'filter[]': 'availableForUnits,true'
          }).$object
      ]

    UnitGroupUsersManagerBase
]
